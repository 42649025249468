<template>
  <div id="centerRight1">
   <dv-scroll-board class="dv-scr-board" :config="config" style="width:380px;height:240px"/>
  </div>
</template>

<script>
export default {
	props:{
		configs: {
		  type: Array,
		  default: () => [],
		},
	},
  data() {
    return {
			config:{
				data:[],
				  rowNum: 5, //表格行数
				  headerHeight: 35,
				  headerBGC: '#0f1325', //表头
				  oddRowBGC: '', //奇数行
				  evenRowBGC: '', //偶数行
				  align: ['left']
			},
      // config: {
      //   data: [
      //     ["<span style='color:#3ed5cb;padding:4px 5px;border-radius: 4px;'>小程序订水</span>家园小区c栋501下单9桶成功"],
      //     ["<span style='color:#3ed5cb;padding:4px 5px;border-radius: 4px;'>小程序订水</span>家园小区c栋501下单9桶成功"],
      //     ["<span style='color:#3ed5cb;padding:4px 5px;border-radius: 4px;'>小程序订水</span>家园小区c栋501下单9桶成功"],
      //     ["<span style='color:#3ed5cb;padding:4px 5px;border-radius: 4px;'>小程序订水</span>家园小区c栋501下单9桶成功"],
      //     ["<span style='color:#3ed5cb;padding:4px 5px;border-radius: 4px;'>小程序订水</span>家园小区c栋501下单9桶成功"],
      //     ["<span style='color:#3ed5cb;padding:4px 5px;border-radius: 4px;'>小程序订水</span>家园小区c栋501下单9桶成功"],
      //     ["<span style='color:#3ed5cb;padding:4px 5px;border-radius: 4px;'>小程序订水</span>家园小区c栋501下单9桶成功"],
      //   ],
     
      // }
    }
  },
	mounted() {
		if(this.configs){
			this.config.data=JSON.parse(JSON.stringify(this.configs))
			console.log(this.config);
		}
		
	}
}
</script>

<style lang="scss" scoped>
.dv-scroll-board .rows .ceil{
	padding: 0 0px !important;
	overflow: initial;
}
.row-item{
	height: 40px !important;
}
.ceil{
	font-size: 12px;
	color: red !important;
}
.label-class{
	color: #8ed5f5 ;
	border-radius: 4px;
}
</style>
