<template>
	<div id="centerRight2">
		<div class="bg-color-black">
			<div class="d-flex ai-center flex-column body-box">
				<dv-capsule-chart class="dv-cap-chart" style="height: 180px;" :config="config" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			dataValue: {
			  type: Array,
			  default: () => [],
			},
			colorValue: {
			  type: Array,
			  default: () => [],
			},
			
		},
		data() {
			return {
				config: {
					showValue:true,
					data: [],
					colors:[],
				}
			}
		},
		created() {
			this.config.data=this.dataValue;
			this.config.colors=this.colorValue;
		}
	}
</script>

<style lang="scss" scoped>
	.dv-cap-chart {
		width: 100%;
		height: 160px;
	}
	.bg-color-black{
		background: transparent;
	}
	.dv-capsule-chart .capsule-item{
		height: 18px !important;
	}
	.dv-capsule-chart .capsule-item .capsule-item-column{
			height: 18px !important;
	}
</style>