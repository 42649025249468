export const weatherOption = {
    weatherCode:{
        "晴": 100,
        "多云": 101,
        "少云": 102,
        "晴间多云": 103,
        "阴": 104,
        "有风": 200,
        "平静": 201,
        "微风": 202,
        "和风": 203,
        "清风": 204,
        "强风/劲风": 205,
        "疾风": 206,
        "大风": 207,
        "烈风": 208,
        "风暴": 209,
        "狂爆风": 210,
        "飓风": 211,
        "龙卷风": 212,
        "热带风暴": 213,
        "阵雨": 300,
        "强阵雨": 301,
        "雷阵雨": 302,
        "强雷阵雨": 303,
        "雷阵雨伴有冰雹": 304,
        "小雨": 305,
        "中雨": 306,
        "大雨": 307,
        "极端降雨": 308,
        "毛毛雨/细雨": 309,
        "暴雨": 310,
        "大暴雨": 311,
        "特大暴雨": 312,
        "冻雨": 313,
        "小到中雨": 314,
        "中到大雨": 315,
        "大到暴雨": 316,
        "暴雨到大暴雨": 317,
        "大暴雨到特大暴雨": 318,
        "雨": 399,
        "小雪": 400,
        "中雪": 401,
        "大雪": 402,
        "暴雪": 403,
        "雨夹雪": 404,
        "雨雪天气": 405,
        "阵雨夹雪": 406,
        "阵雪": 407,
        "小到中雪": 408,
        "中到大雪": 409,
        "大到暴雪": 410,
        "雪": 499,
        "薄雾": 500,
        "雾": 501,
        "霾": 502,
        "扬沙": 503,
        "浮尘": 504,
        "沙尘暴": 507,
        "强沙尘暴": 508,
        "浓雾": 509,
        "强浓雾": 510,
        "中度霾": 511,
        "重度霾": 512,
        "严重霾": 513,
        "大雾": 514,
        "特强浓雾": 515,
        "热": 900,
        "冷": 901,
        "未知": 999,
    }
};
