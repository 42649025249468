<template>
	<div id="index" ref="appRef">
		<div class="bg">
			<dv-loading v-if="loading">Loading...</dv-loading>
			<div v-else class="host-body">
				<!-- 顶部 -->
				<div class="d-flex jc-center">
					<dv-decoration-10 class="dv-dec-10" />

					<div class="d-flex jc-center">
						<dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
						<div class="title">
							<span class="title-text">六盘情数据大屏</span>
							<!-- <dv-decoration-6 class="dv-dec-6" :reverse="true" :color="['#50e3c2', '#67a1e5']" /> -->
							<div class="title-time d-flex">
								<!-- <p>
									订单系统实时客户访问
									<span class="people-num">222</span>
								</p> -->
								<!-- {{ dateWeek }}  -->
								<span>当前时间：{{ dateYear }} {{ dateDay }}</span>
								<!-- <span>平稳运行时间：98小时48分钟</span> -->
							</div>
						</div>
						<dv-decoration-8 class="dv-dec-8" :reverse="true" :color="decorationColor" />
					</div>


					<dv-decoration-10 class="dv-dec-10-s" />
				</div>

				<div class="d-flex" style="justify-content: space-between;" v-if="JSON.stringify(customerObj)!='{}'">
					<!-- 左侧 -->
					<div>
						<div class="max-left">
							<div class="d-flex" style="align-items: center;">
								<div class="public-btn">订单情况</div>
								<div class="title-tianq d-flex">
									<!-- <img v-if="dataWeather.icontemp" :src="dataWeather.icontemp" alt=""> -->
									<div class="">
										<p>今天</p>
										<p>{{dataWeather.peraturetemp}} （{{dataWeather.weathertemp}}）</p>
									</div>
								</div>
							</div>
							<div class="order-number">
								<img src="../assets/icons.png" alt="">
								<div class="order-list">
									<p>
										<span></span>
										<span>未指派订单</span>
										<span>{{customerObj.dayOrderStatisticVO.unAssign}}单</span>
									</p>
									<p>
										<span></span>
										<span>配送中订单</span>
										<span>{{customerObj.dayOrderStatisticVO.inDelivery}}单</span>
									</p>
									<p>
										<span></span>
										<span>已送达订单</span>
										<span>{{customerObj.dayOrderStatisticVO.delivered}}单</span>
									</p>
									<p>
										<span></span>
										<span>异常订单</span>
										<span>{{customerObj.dayOrderStatisticVO.abnormal}}单</span>
									</p>
								</div>
							</div>

						</div>

						<div class="max-left marginTop">
							<div class="public-btn">推广情况</div>
							<div class="d-flex max-flex-dis  font-size12" style="margin-top: 10px;">
								<p></p>
								<p>人数</p>
							</div>
							<centerRight2 :dataValue='dataValue0' />
						</div>

						<div class="max-left marginTop">
							<div class="public-btn">区域订单</div>
							<div class="class-max">地区（TOPS）</div>
							<centerRight2 :dataValue='dataValue' :colorValue='colorValue' />
						</div>
					</div>
					<!-- 中间 -->
					<div class="center-max">
						<div class="cus-total paddingTop">
							<div class="d-flex">
								<div class="list-totals" style="background: linear-gradient(#acd3f0,#56b2f0);">
									<p>总客户数</p>
									<p>{{customerObj.customerAndOrderStatisticVO.totalCustomer}}</p>
								</div>
								<div class="list-totals" style="background: linear-gradient(#81c2ed,#2c91ed)">
									<p>今日新增客户数</p>
									<p>{{customerObj.customerAndOrderStatisticVO.todayIncreaseCustomer}}</p>
								</div>
								<div class="list-totals" style="background:linear-gradient( #7e8cd9, #4962d9);">
									<p>总订单数</p>
									<p>{{customerObj.dayOrderStatisticVO.totalOrder}}</p>
								</div>
								<div class="list-totals" style="background: linear-gradient(#cdb3f2,#9c7df2);">
									<p>今日订单数</p>
									<p>{{customerObj.dayOrderStatisticVO.todayIncreaseOrder}}</p>
								</div>
							</div>
						</div>
						<div class="cus-map">
							<div class="map-title">
								实时数据
								<dv-decoration-10 style="width:380px;height: 1px;" />
								<div class="num-max" style="z-index: 999;">
									<dv-scroll-board class="dv-scr-board" :config="configs" style="width:380px;height:240px" />
									<!-- <centerRight1 :config='configs' /> -->
								</div>
								<!-- 地图 -->
								<div class="maps-class" style="margin-top: 40px;">
									<div id="mainMap" class="map" style="width: 480px;height:500px;"></div>
								</div>
							</div>
							<div class="stores-class">
								<div>
									<img src="../assets/store.png" alt="">
									<div>门店 {{positionObj.shopPositionVO?positionObj.shopPositionVO.length:0}}</div>
								</div>
								<div>
									<img src="../assets/song.png" alt="">
									<!-- {{customerObj.dayOrderStatisticVO}} -->
									<div>配送员 {{positionObj.staffPositionVO?positionObj.staffPositionVO.length:0}}</div>
								</div>
							</div>

						</div>
					</div>
					<!-- 右侧 -->
					<div>
						<div class="max-left-1">
							<div class="public-btn">订单来源</div>
							<div class="title-echarts">
								<!-- '#F5A60A', '#3F8FFF','#1FC48D' -->
								<!-- {{}} -->
								<div v-for="(item,index) in customerObj.customerSourceVO" :key='index'>
									<p class="num-1"><span style="background: #F5A60A;"></span>{{item.createMethod}}订水</p>
									<p class="num">{{item.percentage}}%</p>
								</div>
							</div>
							<div class="test2"
								style="width:100%;height:300px; display:flex;align-items: center;justify-content: center;margin-top: -50px;">
								<div id="myChart" style="width:450px;height:300px;"></div>
							</div>
						</div>


						<!-- 	<div class="max-left marginTop">
							<div class="class-max" style="margin-top: 10px;">推广情况</div>
							<div class="d-flex max-flex-dis  font-size12">
								<p>转化率</p>
								<p>人数</p>
							</div>
						</div> -->

						<div class="max-left-max marginTop">
							<div class="public-btn">订单通知</div>
							<!-- probleList -->
							<div v-for="(item,index) in probleList" :key='index'>
								<div class="class-fons">{{item.address}}未分配</div>
								<div class="order-err">

									<span v-if="item.state ==0">异常订单</span>
									<span v-if="item.state ==1">加急订单</span>
									<span v-if="item.state ==2">跨域订单</span>
									<span v-if="item.state ==5">超时订单</span>
									ID:{{item.orderSn}}
								</div>
								<div class="shop-no">
									<div class="shop-add">
										<span></span>
										所属门店：{{item.shopName}}
									</div>
								</div>
								<div class="shop-no">
									<div class="shop-add">
										<span></span>
										超出时长
									</div>
									<div class="shop-orno" style="color: red;">{{timediff(item.appointmentDeliveryEndTime)}}</div>
								</div>
							</div>
							<div class="">
								<div class="public-btn" style="margin-top: 40px;margin-bottom: 28px;">生活监控</div>
								<div>

									<!--  -->

									<div v-for="(itemMax,indMax) in gallery" :key="indMax" v-show="indMax==index" @mouseover="overHd"
										@mouseout="auto">
										<div class="swiper">
											<div class="swiper-item" v-for="(item,ind) in itemMax" :key="ind" @click='bigMethod(item)'>
												<!-- <img :src="item" class="img-th" /> -->
												<iframe class="img-th" :src="'https://open.ys7.com/ezopen/h5/iframe?url=ezopen://open.ys7.com/'+item.deviceSerial+'/'+item.channelNo+'.live&autoplay=1&accessToken='+accessToken" width="90" height="90" id="ysOpenDevice" allowfullscreen :id="'iframe'+item.id"></iframe>
											</div>
										</div>
										<div class="thumbs">
											<span class="thumb" :class="{'active' :item==index+1}" v-for="item in gallery.length" :key='item'
												@click="index=item-1">{{item}}</span>
										</div>
									</div>
								</div>
							</div>
							<iframe v-if="bigShow" class="img-th"
								:src="'https://open.ys7.com/ezopen/h5/iframe?url=ezopen://open.ys7.com/'+bigObj.deviceSerial+'/'+bigObj.channelNo+'.live&autoplay=1&accessToken='+accessToken"
								width="100vw" height="100vh" id="ysOpenDevice" allowfullscreen></iframe>
							<div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import $ from 'jquery'
	import {
		weatherOption
	} from '../config/weatherOption'
	import drawMixin from "../utils/drawMixin";
	import {
		formatTime
	} from '../utils/index.js'
	import centerRight2 from './centerRight2'

	import centerRight1 from './centerRight1'
	import {
		getChinaJson
	} from "../api/get-json";

	import VueAMap from 'vue-amap'
	let amapManager = new VueAMap.AMapManager();
	import AES from "@/common/crypto.js"
	export default {
		mixins: [drawMixin],
		data() {
			return {
				https: 'http://chaopin.liupanqing.cn',
				// https: 'http://192.168.80.71:8091',
				orderList: [],
				probleList: [],
				positionObj: [],
				configs: {
					data: [],
					rowNum: 5, //表格行数
					headerHeight: 35,
					headerBGC: '#0f1325', //表头
					oddRowBGC: '', //奇数行
					evenRowBGC: '', //偶数行
					align: ['left']
				},
				gallery: [],
				//默认显示 
				index: 0,
				dataValue0: [],
				colorValue: ['#37a2da'],
				dataValue: [],
				dataWeather: {
					city: '北京市',
					dayweather: '晴',
					nightweather: '多云',
					weathertemp: '晴', // 根据时间判断选白天还是晚上的天气
					nighttemp: '3℃',
					daytemp: '15℃',
					peraturetemp: '3℃~15℃', // 根据时间判断选白天还是晚上的温度
					icontemp: 'https://cdn.heweather.com/cond_icon/100.png',
				},
				city: {},
				mapIndex: -1,

				myChart: '',
				distributionOptions: '',


				timing: null,
				loading: true,
				dateDay: null,
				dateYear: null,
				dateWeek: null,
				weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
				decorationColor: ['#568aea', '#000000'],
				myCharter: '',
				opinionData2: [],
				customerObj: {},
				liveList: [],
				accessToken: '',
				bigShow: false,
				bigObj: {},
				token: '',
				citys: '',
				citys2: '',
			}
		},
		components: {
			centerRight2,
			centerRight1,
		},
		created() {
			

			// let url = window.location.href;

			// if (url.indexOf("=") != -1) { //是否有参数
			// 	var str = url.split("=");
			// 	let query = str[1]; //window.location.href;
			// 	let data = decodeURIComponent(query);
			// 	var dess = AES.decrypt(data);
			// 	console.log(dess);
			// 	var labes = dess.split("&");

			// 	this.citys = labes[1]?labes[1].split(',')[0]:''; //城市编码
			// 	this.citys2 = labes[1]?labes[1].split(',')[1]:''; //城市
			// 	console.log(this.citys);
			// 	this.token = labes[0];
			// }
			this.token=localStorage.getItem('token');
			if (this.token && this.token != '' && this.token != null) {
				if (this.citys && this.citys != '' && this.citys != null) {
					this.requestCity();
				} else {
					this.requestGetWeather();
				}
				this.bigScreen()
				this.orderScree()
				this.problemOrder()
				this.tokenMethod();
				setInterval(()=>{
					this.bigScreen()
					this.orderScree()
					this.problemOrder()
					this.tokenMethod();
				},30000)
			} else {
				// 去登录页面
				this.loginMes()
				
			}
		},

		mounted() {
			this.timeFn()
			this.cancelLoading();

		},
		beforeDestroy() {
			clearInterval(this.timing)
		},
		methods: {
			group(array, subGroupLength) {
				let index = 0;
				let newArray = [];
				while (index < array.length) {
					newArray.push(array.slice(index, index += subGroupLength));
				}
				this.gallery = newArray;

			},
			bigMethod(item) {
				this.bigObj = item;
				this.bigShow = true;
			},
			// 监控
			liveMethod() {
				$.get({
					//这里的url不能写到data对象的外面
					url: this.PHTH + '/six-emotions-admin/data/bigScreen/getLiveAddress',
					headers: {
						'X-Access-Token': this.token,
						'Content-Type': 'application/x-www-form-urlencoded'
					},
					data: {
						pageNo: 1,
						pageSize: 10,
					},
					success: (data) => {
						if(data.code == 200){
							this.liveList = data.result;
							this.group(this.liveList, 8);
						}else if(data.code == 401){
							// 去登录页面
							this.loginMes()
						}
						
					},error:err=>{
						if(err.status){
							this.loginMes()
						}
					}
				})
			},
			// 
			tokenMethod() {
				$.get({
					//这里的url不能写到data对象的外面
					url: this.PHTH + '/six-emotions-admin/data/bigScreen/accessToken',
					headers: {
						'X-Access-Token': this.token,
						'Content-Type': 'application/x-www-form-urlencoded'
					},
					success: (data) => {
						this.accessToken = data.result;
						this.liveMethod()
						console.log(this.accessToken);
					}
				})
			},
			// 请求 主页
			bigScreen() {
				$.get({
					//这里的url不能写到data对象的外面
					url: this.PHTH + '/six-emotions-admin/data/bigScreen/index',
					headers: {
						'X-Access-Token': this.token,
						'Content-Type': 'application/x-www-form-urlencoded'
					},
					success: (data) => {
						console.log(data);
						if(data.code == 200){
							this.customerObj = data.result;
							this.dataValue0 = [{
									name: '进入系统',
									value: data.result.promotionSituationVO.login,
								},
								{
									name: '点击订水',
									value: data.result.promotionSituationVO.addCart,
								},
								{
									name: '下单',
									value: data.result.promotionSituationVO.submitOrder,
								},
								{
									name: '支付成功',
									value: data.result.promotionSituationVO.paySuccess,
								},
							];
							if (data.result.regionalSourceVO.length > 0) {
								this.dataValue = [];
								data.result.regionalSourceVO.map(item => {
									var obj = {
										name: item.city,
										value: item.number,
									}
									this.dataValue.push(obj)
								})
							}
							
							//获取客户来源数据
							if (data.result.customerSourceVO.length > 0) {
								this.opinionData2 = [];
								data.result.customerSourceVO.forEach((item, index) => {
									this.opinionData2.push({
										"name": item.createMethod,
										"value": item.percentage,
									})
								})
								setTimeout(() => {
									this.drawLine();
								}, 500)
							}
							
							
							// customerObj.customerSourceVO
							// this.opinionData2= [{
							// 		value: 40,
							// 		name: '电话订水40%',
							// 		itemStyle: '#F5A60A'
							// 	},
							// 	{
							// 		value: 30,
							// 		name: '小程序订水30%',
							// 		itemStyle: '#3F8FFF'
							// 	},
							// 	{
							// 		value: 30,
							// 		name: '其他订水30%',
							// 		itemStyle: '#1FC48D'
							// 	},
							
							// ];
						}else if(data.code == 401){
							// 去登录页面
							this.loginMes()
						}
						
					},error:err=>{
						if(err.status){
							this.loginMes()
						}
					}
				})
			},
			// 查询订单
			orderScree() {
				$.get({
					//这里的url不能写到data对象的外面
					url: this.PHTH + '/six-emotions-admin/data/bigScreen/list',
					headers: {
						'X-Access-Token': this.token,
						'Content-Type': 'application/x-www-form-urlencoded'
					},
					data: {
						orderStatus: 0,
						pageNum: 1,
						pageSize: 10,
					},
					success: (data) => {
						
						if(data.code == 200){
							this.orderList = data.result.records;
							if (this.orderList.length > 0) {
								this.orderList.map(item => {
									let string = item.createMethod == 1 ? '小程序订水' : item.createMethod == 3 ? '系统订水' : '其他订水';
									var lists = ["<span style='color:#3ed5cb;padding:4px 5px;border-radius: 4px;'>" + string +
										"</span>" + item.address + "下单成功"
									];
									this.configs.data.push(lists)
								})
							}
						}else if(data.code == 401){
							// 去登录页面
							this.loginMes()
						}
						
					},error:err=>{
						if(err.status){
							this.loginMes()
						}
					}
				})
			},
			loginMes(){
				localStorage.removeItem('token');
				this.$router.push('/login')
			},
			// 查询区域
			positionMethod() {
				$.get({
					//这里的url不能写到data对象的外面
					url: this.PHTH + '/six-emotions-admin/data/bigScreen/getPosition',
					headers: {
						'X-Access-Token': this.token,
						'Content-Type': 'application/x-www-form-urlencoded'
					},
					data: {
						cityName: this.city.city,
					},
					success: (data) => {
						if(data.code == 200){
							this.positionObj = data.result;
							setTimeout(()=>{
								this.getEchartMap()
							},500)
						}else if(data.code == 401){
							// 去登录页面
							this.loginMes()
						}
						
					},error:err=>{
						if(err.status){
							this.loginMes()
						}
					}
				})
			},
			// 异常订单查询
			problemOrder() {
				$.get({
					//这里的url不能写到data对象的外面
					url: this.PHTH + '/six-emotions-admin/data/bigScreen/list',
					headers: {
						'X-Access-Token': this.token,
						'Content-Type': 'application/x-www-form-urlencoded'
					},
					data: {
						orderStatus: 2,
						pageNum: 1,
						pageSize: 1,
					},
					success: (data) => {
						if(data.code == 200){
							this.probleList = data.result.records;
						}else if(data.code == 401){
							// 去登录页面
							this.loginMes()
						}
						
					},error:err=>{
						if(err.status){
							this.loginMes()
						}
					}
				})
			},



			//鼠标一开  auto自动执行
			auto() {

				// this.stopID = setInterval(() => {
				// 	this.index++;
				// 	this.check();
				// }, 2000)
			},
			overHd() {
				clearInterval(this.stopID);

			},
			//检查边界
			check() {
				if (this.index == this.gallery.length) {
					this.index = 0;
				}
			},
			getEchartMap() {
				let _this = this;
				let res = require('../../public/map/city/' + _this.city.adcode + '.json');
				let myChart = _this.$echarts.init(document.getElementById("mainMap"));
				_this.$echarts.registerMap(_this.city.city, res, {});
				var mapJsonlen = res.features.length,
					data = res.features;
				_this.changeOptions(_this.city.city)
				myChart.setOption(_this.distributionOptions);

			},
			addImage(params, api, realData) {
				this.mapIndex += 1;
				let datas = realData[this.mapIndex].status;
				return {
					type: 'image',
					style: {
						image: datas == 0 ? 'https://six-sets.oss-cn-beijing.aliyuncs.com/upload/map-1_1687763743080.png' :
							'https://six-sets.oss-cn-beijing.aliyuncs.com/upload/img_v2_5e063f8e-58ff-4c0f-8b18-99f7ce9e661g.png',
						x: api.coord([
							realData[params.dataIndex].lng, realData[params.dataIndex]
							.lat
						])[0],
						y: api.coord([
							realData[params.dataIndex].lng, realData[params.dataIndex].lat
						])[1],
						width: 28,
						height: 27,
					}
				}
			},
			changeOptions(name) {
				var that = this;
				that.mapIndex = -1;
				let listpo = [];
				if (this.positionObj.shopPositionVO.length > 0) {
					this.positionObj.shopPositionVO.map(item => {
						let obj = {
							lng: item.longitude,
							status: 1,
							stationname: item.shopName,
							lat: item.latitude,
						}
						listpo.push(obj)
					})
				}

				let listpos = [];
				if (this.positionObj.staffPositionVO!=null && this.positionObj.staffPositionVO.length > 0) {
					this.positionObj.staffPositionVO.map(item => {
						let obj = {
							lng: item.longitude,
							status: 0,
							stationname: item.staffName,
							lat: item.latitude,
						}
						listpos.push(obj)
					})
				}
				// 经纬度数据
				const seriesList = [{
					data: []
				}, ];
				seriesList[0].data = listpo.concat(listpos);
				// 图标
				const series = seriesList.map(v => {
					return {
						name: '地图',
						type: "custom", //配置显示方式为用户自定义
						coordinateSystem: "geo",
						renderItem: function(params, api) { //具体实现自定义图标的方法

							return that.addImage(params, api, v.data);
						},
						data: v.data
					};
				});

				// options
				this.distributionOptions = {
					tooltip: { // 提示框组件
						show: true, // 显示提示框组件
						trigger: "item", // 触发类型
						triggerOn: "mousemove", // 出发条件
						formatter: '',
						// markerIcon
					},
					series, // 数据
					geo: {
						map: this.city.city, // 引入地图 省份或者 国家
						layoutCenter: ["50%", "50%"], //设置后left/right/top/bottom等属性无效
						layoutSize: "45%",
						roam: false, //开启鼠标缩放和漫
						zoom: 2,
						label: {
							normal: {
								//静态的时候展示样式
								show: true, //是否显示地图省份得名称
								textStyle: {
									color: "#fff",
									fontSize: 10,
									fontFamily: "Arial"
								}
							},
							emphasis: { // 高亮状态下的样式
								//动态展示的样式
								color: "#fff"
							}
						},
						itemStyle: { // 地图区域的多边形 图形样式。
							normal: {
								borderColor: "#07919e", // 边框颜色
								areaColor: "#13152a", //  区域颜色
								textStyle: { // 文字颜色
									color: "#fff"
								},
								// shadowBlur: 10, // 图形阴影的模糊大小
								// shadowOffsetX: 10 // 阴影水平方向上的偏移距离。
							},
							emphasis: {
								areaColor: "#464a59",
								color: "#fff"
							}
						}
					}
				};
			},
			// 点击播放监控
			playVideo() {
				this.$refs['playerObj'].videoSrc = 'rtmp://xxxxxxxx'
				this.$refs['playerObj'].playerOptions.sources[0].src = 'rtmp://xxxxxxxx'
			},
			// 获取天气
			requestCity() {
				$.get({
					//这里的url不能写到data对象的外面
					url: 'https://query.asilu.com/weather/baidu',
					dataType: 'jsonp', // 请求方式为jsonp
					data: {
						city: this.citys2,
					},
					success: (data) => {
						this.city.city = data.city;
						this.city.adcode = this.citys;
						let city = this.city;
						let weathertemp = data.weather[0].weather;
						let peraturetemp = data.weather[0].temp;
						// 
						this.positionMethod()
						let vas = data.weather[0].icond;
						let icontemp = '';
						if (vas == 203 || vas == 201 || vas == 202 || vas == 100 || vas == 200 || vas == 204 || vas ==
							208 ||
							vas == 900 || vas == 208) { //晴
							icontemp = 'https://cdn.asilu.com/weather/icon/40/d00.png';
						} else if (vas == 103) { //阴转多云
							icontemp = 'https://cdn.asilu.com/weather/icon/40/d02.png';
						} else if (vas == 101) { //多云
							icontemp = 'https://cdn.asilu.com/weather/icon/40/d01.png';
						} else if (vas == 315 || vas == 409 || vas == 306 || vas == 305 || vas == 313 || vas == 316 ||
							vas ==
							410 || vas == 311 || vas == 318 || vas == 307 || vas == 314 || vas == 305 || vas == 301 ||
							vas ==
							303 || vas == 310 || vas == 317 || vas == 308 || vas == 309 || vas == 300 || vas == 407 ||
							vas ==
							399 || vas == 302) { //雨
							icontemp = 'https://cdn.asilu.com/weather/icon/40/d01.png';
						} else { //多云
							icontemp = 'https://cdn.asilu.com/weather/icon/' + data.weather[0].icond +
								'.png';
						}

						this.dataWeather = {
							city,
							icontemp,
							weathertemp,
							peraturetemp,
						}
					}
				})

			},
			// 获取天气
			requestGetWeather() {

				$.get({
					//这里的url不能写到data对象的外面
					url: 'https://query.asilu.com/weather/gaode',
					dataType: 'jsonp', // 请求方式为jsonp
					data: {
						address: this.citys,
					},
					success: (data) => {
						this.city = data.forecasts[0];
						this.positionMethod()
						if (data.status === '1') {
							let currTime = new Date().getHours();
							let dailyForecast = data.forecasts[0].casts[0];
							let city = data.forecasts[0].city;
							let daytemp = dailyForecast.daytemp + '℃';
							let nighttemp = dailyForecast.nighttemp + '℃';
							let weathertemp = '';
							let peraturetemp = '';
							if ((currTime >= 6) && (currTime < 18)) {
								weathertemp = dailyForecast.dayweather;
								peraturetemp = dailyForecast.nighttemp + "℃~" + dailyForecast.daytemp + "℃";
							} else {
								weathertemp = dailyForecast.nightweather;
								peraturetemp = dailyForecast.daytemp + "℃~" + dailyForecast.nighttemp + "℃";
							}
							let vas = weatherOption.weatherCode[weathertemp];
							let icontemp = '';
							if (vas == 203 || vas == 201 || vas == 202 || vas == 100 || vas == 200 || vas == 204 || vas ==
								208 ||
								vas == 900 || vas == 208) { //晴
								icontemp = 'https://cdn.asilu.com/weather/icon/40/d00.png';
							} else if (vas == 103) { //阴转多云
								icontemp = 'https://cdn.asilu.com/weather/icon/40/d02.png';
							} else if (vas == 101) { //多云
								icontemp = 'https://cdn.asilu.com/weather/icon/40/d01.png';
							} else if (vas == 315 || vas == 409 || vas == 306 || vas == 305 || vas == 313 || vas == 316 ||
								vas ==
								410 || vas == 311 || vas == 318 || vas == 307 || vas == 314 || vas == 305 || vas == 301 ||
								vas ==
								303 || vas == 310 || vas == 317 || vas == 308 || vas == 309 || vas == 300 || vas == 407 ||
								vas ==
								399 || vas == 302) { //雨
								icontemp = 'https://cdn.asilu.com/weather/icon/40/d01.png';
							} else { //多云
								icontemp = 'https://cdn.asilu.com/weather/icon/' + weatherOption.weatherCode[weathertemp] +
									'.png';
							}

							// let icontemp = 'https://cdn.asilu.com/weather/icon/' + weatherOption.weatherCode[weathertemp] +'.png';


							this.dataWeather = {
								city,
								daytemp,
								nighttemp,
								weathertemp,
								peraturetemp,
								icontemp
							}
						}
					}
				})
			},
			drawLine() {
				this.myCharter = this.$echarts.init(document.getElementById('myChart'));

				this.myCharter.setOption({
					title: {
						text: '', // 主标题
						subtext: '', // 副标题
						x: 'center' // x轴方向对齐方式
					},
					grid: {
						containLabel: true
					},
					color: ['#F5A60A', '#3F8FFF', '#1FC48D'],
					series: [{
						name: '',
						type: 'pie',
						radius: ['20%', '45%'],
						avoidLabelOverlap: false,
						// center: ['40%', '50%'],
						itemStyle: {
							emphasis: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							},
							color: function(params) {
								// 自定义颜色
								var colorList = ['#F5A60A', '#3F8FFF', '#1FC48D']
								return colorList[params.dataIndex]
							}
						},
						data: this.opinionData2
					}]
				})
			},
			//计算两个时间差  返回天小时分钟
			timediff(end_time) {
				// 2022-02-28 10:57:12
				let date = new Date();
				var begin_time = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date
					.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
				//年月日时分秒转换为时间戳
				let beginTime = (new Date(begin_time).getTime()) / 1000;
				let endTime = (new Date(end_time).getTime()) / 1000;
				var starttime = ''
				var endtime = ''
				if (beginTime < endTime) {
					starttime = beginTime;
					endtime = endTime;
				} else {
					starttime = endTime;
					endtime = beginTime;
				}
				//计算天数
				var timediff = endtime - starttime;
				var days = parseInt(timediff / 86400);
				//计算小时数
				var remain = timediff % 86400;
				var hours = parseInt(remain / 3600);
				//计算分钟数
				var remain = remain % 3600;
				var mins = parseInt(remain / 60);
				var res = days + '天' + hours + '小时' + mins + '分';
				return res;
			},
			timeFn() {
				this.timing = setInterval(() => {
					this.dateDay = formatTime(new Date(), 'HH: mm: ss')
					this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
					this.dateWeek = this.weekday[new Date().getDay()]
				}, 1000)
			},
			cancelLoading() {
				setTimeout(() => {
					this.loading = false
				}, 500)
			},

		}
	}
</script>

<style lang="scss" scoped>
	@import '../assets/scss/index.scss';

	.class-fons {
		font-size: 14px;
		margin: 20px 0px;
	}

	.bg-color-black {
		background: transparent !important;
	}

	.IndexRanking {
		.content {
			.title {
				display: flex;
				justify-content: space-between;
				margin-top: 70px;

				.icon-css {
					font-size: 30px;
					color: #0066FF;
				}

				span {
					font-size: 24px;
					line-height: 20px;
					color: #333;
					margin-left: 15px;
					vertical-align: baseline;
				}
			}

			.content {
				.swiper-container {
					width: 100%;
					height: 140px;
					margin-top: 28px;
					box-sizing: border-box;
					padding-right: 40px;

					.swiper-wrapper {
						.swiper-slide {
							overflow: auto;
							display: flex;
							width: 240px !important;
							height: 120px;
							background: #FFFFFF;
							border: 1px solid #f5f5f5;
							box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
							border-radius: 10px;
							margin-right: 17px;
							box-sizing: border-box;

							&:first-child {
								margin-left: 4px;
							}

							&:last-child {
								margin-right: 4px;
							}

							.swiper-left {

								/*width: 100%;*/
								/*height: 100%;*/
								img {
									width: 80px;
									height: 80px;
									border-radius: 50%;
									margin: 20px;
								}
							}

							.swiper-right {
								position: relative;
								width: 200px;

								h3 {
									color: #333;
									font-weight: bold;
									font-size: 18px;
									line-height: 23px;
									margin-top: 30px;
								}

								p {
									margin-top: 12px;

									span {
										color: #333;
										font-size: 14px;
										line-height: 18px;
										margin-left: 4px;
									}

									.icon-css {
										color: #EA5504;
									}
								}

								.ranking {
									position: absolute;
									top: 0;
									right: 0;

									.icon-css {
										font-size: 30px;
										color: #FEE701;
									}

									span {
										position: absolute;
										top: 10px;
										left: 7px;
										display: block;
										width: 16px;
										height: 16px;
										line-height: 16px;
										color: #fff;
										background-color: #F5B319;
										text-align: center;
										border-radius: 50%;
									}
								}

								.ranking4 {
									position: absolute;
									top: 0;
									right: 0;

									.icon-css {
										display: block;
										position: absolute;
										width: 0;
										height: 0;
										border-top: 30px solid #0066FF;
										border-left: 30px solid transparent;
										top: 0;
										right: 0;
									}

									span {
										position: absolute;
										top: 0;
										left: -16px;
										display: block;
										color: #fff;
										font-size: 13px;
										transform: rotate(45deg);
									}
								}
							}
						}

						.swiper-slide {
							&:nth-child(2) {
								.ranking {
									.icon-css {
										color: #90C3F0;
									}

									span {
										background-color: #4E9EE5;
									}
								}
							}

							&:nth-child(3) {
								.ranking {
									.icon-css {
										color: #D8A18A;
									}

									span {
										background-color: #DA784F;
									}
								}
							}
						}
					}
				}

				.loadMore {
					height: 120px;
					padding: 0 10px;
					border: 1px solid #0066ff;

					span {
						color: #0066ff;
						-webkit-writing-mode: vertical-rl;
						writing-mode: vertical-rl;
					}
				}
			}
		}
	}
</style>